<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="container">
      <div v-for="(item, i) in items" :key="i" class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <p class="title">{{ item.rank }}</p>
          </div>
          <div :class="['flip-card-back', item.isOpen ? 'stay' : '']">
            <p class="title">{{ item.desc }}</p>
            <p v-if="!item.isOpen" @click="stayOpen(i)">Keep Open</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { TOP } from "../data/resolutions.json";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  setup() {
    const items = reactive(TOP);

    const stayOpen = (i) => {
      items[i].isOpen = true;
    }

    return {
      items,
      stayOpen,
    };
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
.flip-card {
  background-color: transparent;
  width: 90%;
  height: 180px;
  perspective: 1000px;
  margin: 40px auto;
  font-family: sans-serif;
  cursor: pointer;
}
.title {
  font-size: 2em;
  font-weight: 900;
  text-align: center;
  margin: 0 auto;
  max-width: 75%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid coral;
  border-radius: 1rem;
}

.flip-card-front {
  background: linear-gradient(
    120deg,
    bisque 60%,
    rgb(255, 231, 222) 88%,
    rgb(255, 211, 195) 40%,
    rgba(255, 127, 80, 0.603) 48%
  );
  color: coral;
}

.flip-card-back {
  background: linear-gradient(
    120deg,
    rgb(255, 174, 145) 30%,
    coral 88%,
    bisque 40%,
    rgb(255, 185, 160) 78%
  );
  color: white;
  transform: rotateY(180deg);
}
.stay {
  transform: rotateY(0deg);
}
</style>
